import { Grid, Typography } from '@mui/material';

import useHotelService from './hotel-ser-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';
import { useParams, useSearchParams } from 'react-router-dom';
import useSubProperty from '../SubProperty/sub-property-hooks';
import { TalkToUS } from '../../components/TalkToUs';
import { customTheme } from '../../constants';
import { ThemeProvider } from '@material-ui/styles';
import RichTextContent from '../../components/RTEContent';
const Services = () => {
  const [searchParams] = useSearchParams();
  const SPID = searchParams.get('SPID');
  const { hotelService, loading, chatText } = useHotelService();
  const {
    subProperties,
    loading: spLoading,
    showChatIcon,
    headerTitle
  } = useSubProperty();
  const { id } = useParams();
  if (loading || spLoading) {
    return <FullScreenLoader />;
  }
  const service = hotelService?.find(hs => hs.id === id);
  return (
    <Layout>
      <HeaderLayout title={(headerTitle as string) || 'Facilities'}>
        {showChatIcon && (
          <TalkToUS
            sx={{ alignItems: 'center' }}
            chat_text={chatText}
          ></TalkToUS>
        )}
      </HeaderLayout>
      <ThemeProvider theme={customTheme}>
        <Grid
          container
          spacing={2}
          sx={{
            px: 2,
            py: 0,
            flex: 1,
            mt: '0px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
        >
          {service?.desc ? (
            <Grid item width={'100%'}>
              {service?.desc?.toString().startsWith('<') ? (
                <RichTextContent content={service?.desc?.toString()} />
              ) : (
                <Typography sx={{ color: 'text.secondary' }}>
                  {service?.desc}
                </Typography>
              )}
            </Grid>
          ) : SPID ? (
            Array.isArray(subProperties) &&
            subProperties
              ?.find(sp => sp?.id === SPID)
              ?.sub_services?.filter(ss =>
                hotelService
                  ?.find(hs => hs.id === id)
                  ?.sub_services?.some(ess => ss?.name === ess?.name)
              )
              ?.map((hotelService: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <ServiceCard
                      showInfo={true}
                      key={hotelService?.name}
                      label={hotelService?.name}
                      description={hotelService?.desc}
                      image={hotelService?.img as string}
                      serviceID={hotelService?.service_id}
                      booking={hotelService?.booking_url}
                      bookingForm={hotelService?.booking_form}
                      spaIntegration={hotelService?.spa_integration}
                    />
                  </Grid>
                );
              })
          ) : (
            Array.isArray(hotelService) &&
            hotelService
              ?.find(service => service.id === id)
              ?.sub_services?.map((hotelService: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <ServiceCard
                      showInfo={true}
                      key={hotelService?.name}
                      label={hotelService?.name}
                      description={hotelService?.desc}
                      image={hotelService?.img as string}
                      serviceID={hotelService?.service_id}
                      booking={hotelService?.booking_url}
                      bookingForm={hotelService?.booking_form}
                      spaIntegration={hotelService?.spa_integration}
                        noti_msg={hotelService?.noti_msg}
                    />
                  </Grid>
                );
              })
          )}
        </Grid>
      </ThemeProvider>
    </Layout>
  );
};

export default Services;
